import React, { ReactElement } from 'react'


import { AppComponent } from '../components/app/app.component'
import LoginGuardComponent from '../components/app/login.gaurd.component'
import HeadComponent from '../components/head.component'
import UsersComponent from '../components/users.component'

export default function Upload(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <HeadComponent title="Organization Users" />
        <UsersComponent />
      </LoginGuardComponent>
    </AppComponent>
  )
}
