import React, { ReactElement, useEffect, forwardRef, useState, useContext } from 'react'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { navigate } from 'gatsby'
import { getUsers } from '../services/user.service'
import { AppUser } from '../models/app_user'
import { MessageContext } from '../contexts/message.store'

const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
}
const useStyles = makeStyles((theme: Theme) => ({
  usersWrapper: {},
  userListSection: {},
}))
export default function UsersComponent(): ReactElement {
  const classes = useStyles()
  const [users, setUsers] = useState<AppUser[]>()
  const [, messageDispatch] = useContext(MessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const getApiUsers = async (): Promise<void> => {
    setIsLoading(true)
    try {
      setUsers(await getUsers())
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: err.status })
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getApiUsers()
  }, [])

  return (
    <div className={classes.usersWrapper}>
      <section className={classes.userListSection}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Created', field: 'created_at', type: 'datetime' },
          ]}
          onRowClick={(e, data): void => { navigate(`/users/${data.id}`) }}
          data={users}
          title="Organization Users"
          isLoading={isLoading}
        />
      </section>
    </div>
  )
}
